import { fromUnixTime, differenceInYears, parse, formatDistanceToNow, parseISO } from 'date-fns'

export function calculateAge(dob: string): number {
  const date = parse(dob, 'yyyy-MM-dd', new Date())
  const age = differenceInYears(new Date(), date)
  return age
}

export function timeAgo(datetimeOrTimestamp) {
  try {
    let datetime
    if (isTimestamp(datetimeOrTimestamp)) {
      datetime = fromUnixTime(datetimeOrTimestamp)
    } else {
      datetime = parseISO(datetimeOrTimestamp)
    }
    return formatDistanceToNow(datetime, { addSuffix: true })
  } catch (e) {
    return '' // If the time is invalid - don't crash, display blank.
  }
}

function isTimestamp(ts) {
  return Number(ts) ? new Date(Number(ts)).getTime() > 0 : false
}
