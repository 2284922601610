import { useState, useMemo } from 'react'
import { Select } from '../Select'
import { XStack } from '..'
import { format } from 'date-fns'
import months from './months'

const DatePicker = ({ onChange = (d) => d, value, startYear = 1950 }) => {
  const [date, setDate] = useState(value ? new Date(value) : new Date())
  const MM = useMemo(() => format(date, 'M'), [date])
  const DD = useMemo(() => format(date, 'd'), [date])
  const YY = useMemo(() => format(date, 'yyyy'), [date])

  // Create a new date object and pass it to the parent handler
  const handleDateChange = (YY, MM, DD) => {
    const dateString = `${YY}-${MM}-${DD}`
    const newDate = new Date(dateString)
    setDate(newDate)
    onChange(newDate)
  }

  const handleYearChange = (value) => {
    handleDateChange(value, MM, DD)
  }

  const handleMonthChange = (value) => {
    const newDD = Math.min(DD, getNumDaysInMonth(YY, value))
    handleDateChange(YY, value, newDD)
  }

  const handleDayChange = (value) => {
    handleDateChange(YY, MM, value)
  }

  const getNumDaysInMonth = (year, month) => {
    return new Date(Number(year), Number(month), 0).getDate()
  }

  // Dropdown options for year select field
  // Start year can be passed as props
  // Generates options in the following format:
  // [{id: <startYear>, value: <startYear>},...,{id: <currYear>, value: <currYear>}]
  const yearOptions = Array.from(
    { length: new Date().getFullYear() - startYear + 1 },
    (_, i) => startYear + i
  ).map((year) => ({
    id: year,
    value: year,
  }))

  // Dropdown options for month select field
  // Generates options in the following format:
  // [{id: 'January', value: 1},...,{id: 'December', value: 12}]
  const monthOptions = months.map((month, index) => ({
    id: month,
    value: index + 1,
  }))

  // console.log('monthOptions', monthOptions)

  // Dropdown options for month select field
  // Generates options in the following format:
  // [{id: 1, value: 1},...,{id: 31, value: 31}]
  // Memoizing the values since Days field is dynamic and should get updated based on the month and leap-year
  // Ex: When user cooses Feb, the number of days should be 28/29
  const dayOptions = useMemo(() => {
    const days = Array.from({ length: getNumDaysInMonth(YY, MM) }, (_, i) => i + 1).map((day) => ({
      id: day,
      value: day,
    }))
    return days
  }, [YY, MM])

  console.log('dayOptions', dayOptions)

  return (
    <XStack gap={4} justifyContent="space-between">
      <XStack flexShrink={1}>
        <Select
          id="months"
          options={monthOptions}
          onValueChange={handleMonthChange}
          disablePreventBodyScroll
          placeholder="MM"
          value={MM}
          native={true}
        />
      </XStack>
      <XStack flexShrink={1}>
        <Select
          id="days"
          options={dayOptions}
          onValueChange={handleDayChange}
          disablePreventBodyScroll
          placeholder="DD"
          disabled={true}
          value={DD}
          native={true}
        />
      </XStack>
      <XStack flexShrink={1}>
        <Select
          id="year"
          options={yearOptions}
          onValueChange={handleYearChange}
          disablePreventBodyScroll
          placeholder="YYYY"
          value={YY}
          native={true}
        />
      </XStack>
    </XStack>
  )
}

export { DatePicker }
