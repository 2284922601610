import { decode } from 'base-64'
global.atob = decode
import { YStack, Spinner } from '@bp/ui'
import { useQuery } from '@tanstack/react-query'
import API from 'app/api'
import { useUserInfoStore } from 'app/store'
import { BellPopover } from './popover'
import { useStream } from 'app/lib/stream/useStream'

export default function Bell() {
  const userId = useUserInfoStore((state) => state.id)

  const feedsConnected = useStream((s) => s.feedsConnected)

  if (!feedsConnected) {
    return (
      <YStack justifyContent="center">
        <Spinner />
      </YStack>
    )
  }

  return <BellPopover userId={userId} />
}
