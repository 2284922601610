import { XStack, YStack, Text, Button, Avatar, isWeb } from '@bp/ui'
import { CloseCrossIcon, MoreVerticalIcon, CheckCircleIcon, TrashIcon } from '@bp/ui/src/Iconsx'
import { useState } from 'react'
import { Link } from 'solito/link'
import { useRouter } from 'solito/router'
import { View } from 'tamagui'
import { Pressable } from 'react-native'
import { Popover, AlertDialog } from 'tamagui'
import { timeAgo } from '../../utils/date'
import { useQueryClient, useMutation } from '@tanstack/react-query'

export default function Notification({ notification, setIsOpen, notificationFeed }) {
  const queryClient = useQueryClient()
  const {
    id,
    group,
    activities,
    activity_count: activityCount,
    actor_count: actorCount,
    verb,
    is_read: isRead,
    is_seen: isSeen,
    created_at: createdAt,
    updated_at: updatedAt,
  } = notification
  const router = useRouter()

  const activity = activities.length > 0 ? activities[0] : null
  if (!activity) {
    return null
  }

  if (activity.actor && activity.actor.error) {
    return null
  }

  let activityObject = null
  if (activity.object) {
    try {
      activityObject = JSON.parse(activity.object)
    } catch (e) {
      // Do nothing since we can't get the data
    }
  }

  let link = ``
  let verbText = null
  if (verb === 'like') {
    verbText = 'liked your post'
  }
  if (verb === 'comment') {
    verbText = 'commented on your post'
  }
  if (verb === 'follow') {
    verbText = 'is now following you'
    link = `/user/${activity.actor.data?.username}`
  }
  if (verb === 'invite') {
    verbText = 'invited you to chat'
    link = `/chats/${activityObject.id}`
  }

  const handleLink = (link) => () => {
    if (link) {
      router.push(link)
      setIsOpen(false)
    }
  }

  const [isOptionsOpen, setIsOptionsOpen] = useState(false)
  const handleOptions = () => {
    setIsOptionsOpen((v) => !v)
  }

  const mutationDelete = useMutation({
    mutationFn: () => notificationFeed.removeActivity(activity.id),
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['notificationsData'] }),
  })
  const handleDelete = async () => {
    mutationDelete.mutate()
  }

  const mutationRead = useMutation({
    mutationFn: () => notificationFeed.get({ mark_read: id }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['notificationsData'] })
      setIsOptionsOpen(false)
    },
  })
  const handleRead = async () => {
    mutationRead.mutate()
  }

  if (!verbText) {
    // If we don't support the verb - don't render broken notification.
    console.error('Unsupported verb', verb, notification)
    return null
  }

  return (
    <XStack
      backgroundColor={!isSeen && '$secondaryLighter'}
      padding="$2.5"
      paddingRight={0}
      justifyContent="space-between"
    >
      <View>
        <Pressable onPress={handleLink(link)}>
          <XStack gap="$2.5">
            <Avatar circular size="$3" src={activity.actor.data?.avatar?.md} />
            <YStack justifyContent="center">
              <Text bold={!isRead}>
                {activity.actor.data?.nickname} {verbText}
              </Text>
              <Text fontSize="$1" color="$medium">
                {timeAgo(createdAt)}
              </Text>
            </YStack>
          </XStack>
        </Pressable>
      </View>

      <View>
        {isOptionsOpen && (
          <XStack
            backgroundColor={!isSeen ? '$secondaryLighter' : '$lighter'}
            style={{ position: 'absolute', right: 0, top: 0 }}
          >
            <Button
              icon={mutationDelete.idPending ? <Spinner /> : <TrashIcon size="$1.2" />}
              onPress={handleDelete}
            />
            <Button
              icon={mutationRead.idPending ? <Spinner /> : <CheckCircleIcon size="$1.2" />}
              onPress={handleRead}
            />
            <Button
              icon={
                isOptionsOpen ? <CloseCrossIcon size="$1.2" /> : <MoreVerticalIcon size="$1.2" />
              }
              onPress={handleOptions}
            />
          </XStack>
        )}
        <Button
          icon={isOptionsOpen ? <CloseCrossIcon size="$1.2" /> : <MoreVerticalIcon size="$1.2" />}
          onPress={handleOptions}
        />
      </View>
    </XStack>
  )
}
