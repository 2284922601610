/**
 *
 * Note: This implementation is incomplete and will be revisited as part of different ticket for header. Refer BLAC-742
 *
 */

import { useAuth } from 'app/lib/auth'
import { useLink } from 'solito/link'
import {
  DelayedTextField,
  Button,
  XStack,
  SearchIcon,
  EmailIcon,
  NotificationsIcon,
  useMedia,
  YStack,
  Text,
  Avatar as BareAvatar,
  NiceLink,
  Circle,
  Stack,
} from '@bp/ui'

import Logo from './logo'
import { useState } from 'react'
import Avatar from './avatar'
import Bell from '../bell'
import API from 'app/api'
import { useStream } from 'app/lib/stream/useStream'
import { useRouter } from 'solito/router'

export default function Header({ hideHeader }) {
  const { user, isLoading, login, signup, accessToken } = useAuth()
  const media = useMedia()
  const newMessageIndicator = useStream((s) => s.newMessageIndicator)
  const resetNewMessageIndicator = useStream((s) => s.resetNewMessageIndicator)
  const { push } = useRouter()

  const messagesProps = useLink({
    href: '/messages',
  })
  const handleMessage = () => {
    resetNewMessageIndicator()
    push('/messages')
  }

  const [searchResults, setSearchResults] = useState([])
  const handleSearch = async (value) => {
    const searchResults = await API.search.getPeople(value?.toLowerCase())
    setSearchResults(searchResults.data)
  }

  if (hideHeader) {
    return null
  }

  return (
    <XStack height="72px">
      <XStack
        position="fixed"
        height="72px"
        elevation={3}
        width="100%"
        alignItems="center"
        backgroundColor="$lightest"
        zIndex="999"
        justifyContent="space-between"
        px="$4"
        gap="$6"
      >
        <XStack>
          <Logo scale={0.75} />
        </XStack>
        <YStack $xxs={{ display: 'none' }} flexShrink={1} flexGrow={1}>
          <DelayedTextField
            flexShrink={1}
            flexGrow={1}
            placeholder="Search for people"
            accessibilityLabel="Search for people"
            onChangeText={handleSearch}
            startIcon={<SearchIcon />}
          />
          {searchResults && searchResults.length > 0 && (
            <YStack
              position="absolute"
              top={50}
              background="$lightest"
              padding="$4"
              borderRadius="$4"
              borderColor="$light"
              borderWidth={1}
              gap="$3"
              alignItems="flex-start"
            >
              {searchResults.map((user) => (
                <NiceLink
                  href={`/user/${user.username}`}
                  onPress={() => {
                    setSearchResults(null)
                  }}
                >
                  <XStack gap="$3" key={user.id} alignItems="center">
                    <BareAvatar circular size="$3" src={user.avatar?.md} />
                    <Text>{user.nickname}</Text>
                    <Text variant="footnote">@{user.username}</Text>
                  </XStack>
                </NiceLink>
              ))}
            </YStack>
          )}
        </YStack>

        {accessToken && user && !isLoading ? (
          <XStack gap="$4">
            {media.xxs && (
              <Button
                variant="text"
                {...messagesProps}
                icon={<SearchIcon size={24} />}
                gtXss={{ display: 'none' }}
              />
            )}
            <Stack ai="center" jc="center">
              {newMessageIndicator && (
                <Circle
                  size={10}
                  backgroundColor="$errorDarker"
                  pointerEvents="none"
                  position="absolute"
                  bottom={10}
                  right={0}
                  zIndex={99}
                ></Circle>
              )}

              <Button variant="text" onPress={handleMessage} icon={<EmailIcon size={24} />} />
            </Stack>

            <Bell />
            <Avatar />
          </XStack>
        ) : (
          <XStack gap="$2">
            <Button variant="primary" onPress={login}>
              Login
            </Button>
            <Button variant="accent" onPress={signup}>
              Signup
            </Button>
          </XStack>
        )}
      </XStack>
    </XStack>
  )
}
